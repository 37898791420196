.container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.btn {
  background: #3B5998;
  color: white;
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  min-width: 150px;
  margin-top: 15px;
  text-align: center;
}

.spinner {
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.doubleBounce1, .doubleBounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #3B5998;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.doubleBounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
