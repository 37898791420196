.draftSwitch {
	border-top: 1px solid #ccc;
}

.draftSwitch div:first-child{
	padding: 12px 0;
}

.draftSwitch div:nth-child(2){
	display:flex;
	align-items: center;
	cursor: pointer
}

@media (min-width: 600px){
	.draftSwitch {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

#alert-dialog-description {
	padding-bottom: 20px;
}

#alert-dialog-title {
	padding: 24px 24px 0px;
}
.alert-dialog-error {
	color: #e5362e;
	font-size: 1rem;
	font-family: Lato;
	font-weight: 400;
	padding-top: 5px;
	white-space: pre-line;
}
.dialog-number-input {
	line-height: 2em;
	font-size: 1em;
	width: 60px;
	margin-top: 10px;
}
